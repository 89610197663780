<template>
  <div class="global-container">
    <section class="first-part-container">
      <h1><strong>CITRON</strong> DIGITAL</h1>
      <span style="height: 2px; background-color: rgba(232,255,255,0.25); display: block"></span>
      <p>
        Depuis <strong>2020</strong>, j'accompagne mes clients dans la <strong>création</strong> ou la
        <strong>reprise</strong> de leurs projets. Je suis spécialisé dans le web et le développement d'applications de type SAAS.
      </p>
      <p><router-link :to="'/portfolio'" class="hyperlink">En apprendre plus sur moi</router-link></p>
    </section>
    <div style="height: 80px"></div>
    <div class="footer-container">
      <section>
        <h2>SITE WEB</h2>
        <ul>
          <li>Vitrine</li>
          <li>E-commerce</li>
          <li>Marketplaces</li>
          <li><router-link :to="'/site-web'" class="hyperlink">En savoir plus</router-link></li>
        </ul>
      </section>
      <section>
        <h2>APPLICATION WEB</h2>
        <ul>
          <li>SaaS, <router-link :to="'/application-web'" class="hyperlink">des exemples</router-link></li>
          <li>Accompagnement</li>
          <li>Maintenance</li>
        </ul>
      </section>
      <section>
        <h2>PRESTATION DE SERVICE</h2>
        <ul>
          <li>Conseils</li>
          <li>Développement</li>
          <li>Digitalisation</li>
          <li>SOE, Référencement naturel</li>
          <li><router-link :to="'/contact'" class="hyperlink">Contactez-moi</router-link></li>
        </ul>
      </section>
    </div>
  </div>
  <advices-component/>
  <call-to-action-component/>
</template>

<script>
    import {useHead} from "@vueuse/head";
    import AdvicesComponent from "@/components/AdvicesComponent.vue";
    import CallToActionComponent from "@/components/CallToActionComponent.vue";

    export default {
        name: 'HomeView',
      components: {CallToActionComponent, AdvicesComponent},
        mounted() {
          useHead({
            title: 'Citron Digital - Site vitrine / Application web',
            meta: [
              { name: 'description', content: 'Spécialiste à Toulouse en création de sites vitrines, applications web et logiciels sur mesure. Optimisation SEO intégrée pour un meilleur référencement. Un processus simple : conception, design, développement, livraison et maintenance.'},
              { name: 'keywords', content: 'création site vitrine, SEO, application web, Toulouse, logiciel, SAAS, Toulouse'}
            ]
          });
      }
    }
</script>

<style lang="stylus" scoped>
@import '~/src/common/style/constants.styl';

// ------------------------------------------------------
//                         GLOBAL
// ------------------------------------------------------
.global-container
  min-height 100%
  padding-left 10%
  padding-right 10%
  display flex
  flex-direction column
  justify-content space-between
  align-items: center;

@media only screen and (min-width: DESKTOP_SIZE)
  .global-container
    padding 0

main
  padding-left 5%
  padding-right 5%
// ------------------------------------------------------
//                         HEADER
// ------------------------------------------------------
.first-part-container
  width 100%
  padding-top 50px
  display flex
  flex-direction column
  align-items flex-start

//---------------------- RESPONSIVE ----------------------
@media only screen and (min-width: DESKTOP_SIZE)
  .first-part-container
    display flex
    width 80%

// ------------------------------------------------------
//                         FOOTER
// ------------------------------------------------------
.footer-container
  width 100%
  display flex
  flex-direction column
  align-items center
  section
    min-width 300px
    padding-bottom 30px
    padding-left 15%
    h3
      display block
    li
        display block


//---------------------- RESPONSIVE ----------------------
@media only screen and (min-width: DESKTOP_SIZE)
  .footer-container
    display flex
    justify-content center
    flex-direction row
    gap 6%
    padding-bottom 2em
    align-items flex-start
    section
      padding-left 0


// ------------------------------------------------------
//                         DIVERS
// ------------------------------------------------------

h2
  color THEME_COLOR_SECONDARY

strong
  color THEME_COLOR_SECONDARY

p, li, label
  font-size 1.3em

</style>
