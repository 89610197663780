<template>
  <section ref="banner">
    <div ref="chariot" class="chariot">
      <img ref="image" src="img/citrondigital_background.webp">
      <div class="heartbeat h1"></div>
      <div class="heartbeat h2"></div>
      <div class="heartbeat h3"></div>
      <div class="heartbeat h4"></div>
      <div class="heartbeat h5"></div>
      <div class="heartbeat h6"></div>
      <div class="heartbeat h7"></div>
      <div class="heartbeat h8"></div>
      <div class="heartbeat h9"></div>
      <div class="heartbeat h10"></div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'BannerComponent',
  data() {
    return {
      scrollPosition: 0,
      maxScroll: 600 // Ajuste cette valeur en fonction du moment où l'image doit disparaître
    };
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
    setTimeout(() => {
      this.$refs.chariot.classList.add('move-to-center');
    }, 300); // Animation d'entrée
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.scrollPosition = window.scrollY;

      const maxScroll = this.maxScroll;
      const progress = Math.min(this.scrollPosition / maxScroll, 1); // 0 à 1
      const translateX = 70 + this.scrollPosition / 500 * 150; // 100% = hors écran à droite
      const opacity = 1 - progress; // Diminue progressivement

      this.$refs.chariot.style.transform = `translateX(calc(${translateX}% - 180px))`;
      this.$refs.chariot.style.opacity = opacity;
    }
  }
};
</script>

<style lang="stylus" scoped>
@import '~/src/common/style/constants.styl';

section
  margin-top 60px
  background #113f59
  width 100%
  height 350px
  display flex
  align-items center
  overflow hidden

  .chariot
    width 100%
    position absolute
    left -20%
    transition transform 1.5s cubic-bezier(0.25, 1, 0.5, 1), opacity 0.3s ease-out
    &.move-to-center
      transform translateX(calc(70% - 180px))
    img
      width 300px

  .heartbeat
    top 0
    margin-top 20px
    margin-left 40px
    width 238px
    aspect-ratio 1/1
    position absolute
    z-index -1
    background-color rgba(35, 35, 35, 0.03)
    border-radius 50%
    animation pulse 3s infinite
    &.h1
      width 234px
    &.h2
      margin-top 22px
      margin-left 42px
      width 230px
    &.h3
      margin-top 24px
      margin-left 46px
      width 226px
    &.h4
      margin-top 26px
      margin-left 48px
      width 222px
    &.h5
      margin-top 28px
      margin-left 50px
      width 218px
    &.h6
      margin-top 30px
      margin-left 52px
      width 214px
    &.h7
      margin-top 32px
      margin-left 54px
      width 210px
    &.h8
      margin-top 34px
      margin-left 56px
      width 206px
    &.h9
      margin-top 36px
      margin-left 58px
      width 102px
    &.h10
      margin-top 38px
      margin-left 60px
      width 198px

@keyframes pulse
  0%
    transform scale(1)
  50%
    transform scale(1.1)
  100%
    transform scale(1)
</style>
