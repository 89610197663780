<template>
  <FooterComponent/>
</template>

<script>
    import FooterComponent from "@/components/FooterComponent.vue";

    export default {
        name: 'ArticlesView',
      components: {FooterComponent},
    }
</script>

<style lang="stylus" scoped>
@import '~/src/common/style/constants.styl';
</style>