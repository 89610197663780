<template>
  <section class="showcase-website-section">
    <h1>Développement de logiciels sur-mesure</h1>

    <p>
      Vous avez un logiciel qui ne répond plus à vos besoins ? Vous rencontrez des bugs ou souhaitez ajouter de nouvelles fonctionnalités ?
      Que ce soit pour la maintenance, l’amélioration ou la création d’une solution sur-mesure, je vous accompagne dans tous vos projets de développement logiciel.
    </p>

    <h2>Pourquoi faire appel à mes services ?</h2>

    <p>Je vous propose une expertise complète pour répondre à vos besoins spécifiques :</p>

    <ul>
      <li><strong>Maintenance et correction de bugs</strong> : Votre logiciel présente des erreurs ou des dysfonctionnements ? Je les corrige rapidement pour garantir son bon fonctionnement.</li>
      <li><strong>Amélioration et optimisation</strong> : Besoin d’ajouter des fonctionnalités, d’optimiser la performance ou de moderniser une interface ? Je m’adapte à vos besoins pour améliorer votre solution.</li>
      <li><strong>Tout type de technologies</strong> : Web, desktop, mobile, API… Quelle que soit votre stack technologique, je vous apporte mon expertise.</li>
      <li><strong>Migration et évolution</strong> : Vous souhaitez passer à une nouvelle technologie ou adapter votre logiciel aux nouveaux standards ? Je vous aide à réussir cette transition.</li>
      <li><strong>Sécurité et fiabilité</strong> : Vos données et celles de vos utilisateurs sont précieuses. J’intègre les bonnes pratiques pour un logiciel robuste et sécurisé.</li>
    </ul>

    <h2>Un accompagnement de A à Z</h2>

    <p>
      Je ne me contente pas de coder : je vous conseille et vous guide tout au long du projet.
      De l’analyse de vos besoins à la mise en production, je vous apporte un service clé en main, avec un suivi personnalisé.
    </p>

    <h2>Passez à l’action dès maintenant</h2>

    <p>
      Un logiciel performant fait la différence dans votre productivité et votre compétitivité.
      Ne laissez pas un outil obsolète ou un bug ralentir votre activité !
    </p>

    <p><strong>Discutons de votre projet dès aujourd’hui !</strong> Contactez-moi pour obtenir un devis personnalisé et améliorer votre logiciel.</p>

    <div class="button-container">
      <router-link :to="'/contact'" class="hyperlink">
        <button>
          <span>DEMANDER UN DEVIS</span>
        </button>
      </router-link>
    </div>
  </section>
  <advices-component/>
  <call-to-action-component/>
</template>

<script>
    import {useHead} from "@vueuse/head";
    import AdvicesComponent from "@/components/AdvicesComponent.vue";
    import CallToActionComponent from "@/components/CallToActionComponent.vue";

    export default {
        name: 'SoftwareView',
      components: {CallToActionComponent, AdvicesComponent},
      mounted() {
        useHead({
          title: 'Citron Digital - Développement de Logiciels | Maintenance et Optimisation',
          meta: [
            {
              name: 'description',
              content: 'Optimisez et maintenez vos logiciels avec Citron Digital. Correction de bugs, ajout de fonctionnalités, optimisation de performance et migration vers de nouvelles technologies.'
            },
            {
              name: 'keywords',
              content: 'développement logiciel, maintenance de logiciels, correction de bugs, amélioration de logiciel, optimisation de code, migration technologique, Citron Digital, Toulouse'
            }
          ]
        });
      }

    }
</script>

<style lang="stylus" scoped>
    @import '~/src/common/style/constants.styl';

    .showcase-website-section
      padding-left 10%
      padding-right 10%
      margin-bottom 50px
      h1
        margin-top 2em
        margin-bottom 1em
        text-align center
        font-size 2.8em

    strong
      color THEME_COLOR_SECONDARY

    h2.contact
      padding-top 1em
      text-align center
      font-size 2em

    .button-container
      display flex
      justify-content center
      a.hyperlink
        margin-top 50px
        max-width 380px
        button
          box-shadow 0 0 5px #113f59
          background-color #c39e02
          border none
          cursor pointer
          margin-top 25px
          height 70px
          border-radius 3px
          display flex
          align-items center
          span
            font-size 1.2em
            padding-left 15px
            padding-right 15px
            font-weight bold
            color #162537
</style>
