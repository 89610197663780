<template>
  <section class="showcase-website-section">
    <h1>Offrez de la visibilité à votre entreprise avec un site vitrine</h1>

    <p>
      Aujourd’hui, être présent sur le web n’est plus une option, c’est une nécessité. Un <strong>site vitrine</strong> est la solution idéale pour mettre en avant votre activité, attirer des clients et renforcer votre image de marque.
    </p>

    <p>
      Vous voulez un site qui vous ressemble, performant et optimisé pour le référencement ? Faites appel à mon expertise pour créer un site professionnel, efficace et adapté à vos besoins.
    </p>

    <h2>Pourquoi un site vitrine ?</h2>

    <p>Un site vitrine est bien plus qu’une simple carte de visite en ligne. C’est un <strong>véritable outil de communication</strong> qui vous permet de :</p>

    <ul>
      <li><strong>Améliorer votre visibilité</strong> sur Google et attirer de nouveaux prospects.</li>
      <li><strong>Valoriser votre marque</strong>, vos services et votre savoir-faire.</li>
      <li><strong>Gagner en crédibilité</strong> auprès de vos clients et partenaires.</li>
      <li><strong>Être accessible 24/7</strong>, sans limite de temps ni de frontières.</li>
    </ul>

    <h2>Mon expertise à votre service</h2>

    <h3>Un design sur-mesure</h3>
    <p>Votre site doit refléter votre identité et séduire vos visiteurs au premier regard. Je crée un <strong>design unique et impactant</strong>, pensé pour votre activité.</p>

    <h3>Un référencement optimisé (SEO)</h3>
    <p>Avoir un beau site, c'est bien. Être trouvé sur Google, c'est encore mieux ! Je travaille aussi le référencement naturel pour maximiser votre présence en ligne.</p>

    <h3>Un accompagnement personnalisé</h3>
    <p>De la conception à la mise en ligne, je suis à vos côtés à chaque étape. Vous avez une question ou un besoin spécifique ? Je suis disponible et réactif.</p>

    <h3>Une solution clé en main</h3>
    <p>Hébergement, nom de domaine, maintenance… Je m’occupe de tout pour que vous n’ayez qu’à vous concentrer sur votre activité.</p>

    <h3>Une totale liberté</h3>
    <p>Vous souhaitez changer de prestataire plus tard ? Aucun souci ! Je vous fournis tous les accès et la documentation nécessaires.</p>

    <h2>Passez à l’action dès maintenant</h2>

    <p>
      Ne laissez pas vos concurrents prendre de l’avance. Un <strong>site vitrine bien conçu</strong> peut faire la différence entre un client qui choisit votre entreprise… ou une autre.
    </p>

    <p><strong>💡 Discutons de votre projet dès aujourd’hui !</strong> Contactez-moi pour obtenir un devis personnalisé et booster votre présence en ligne.</p>

    <div class="button-container">
      <router-link :to="'/contact'" class="hyperlink">
        <button>
          <span>DEMANDER UN DEVIS</span>
        </button>
      </router-link>
    </div>

    <!--

    <h1> A proposer uniquement si on remplis un formulaire avec mail et numéro de téléphone</h1>
    <h2>Conception et dévéloppement</h2>
    <p>Je vous propose le pack de base qui comprends : </p>
    <ul>
      <li>Un menu en haut</li>
      <li>Une grande image en dessous</li>
      <li>5 sections (ça peut être la présentation de vos créations, votre contact, vos réseaux sociaux ou présentation de votre entreprise ou de vous, ça peut même être sur mesure selon vos besoins)</li>
      <li>Un bas de page.</li>
    </ul>

    <p>
      Plusieurs allée retour entre vous et moi afin de s'assurer que le site sera conforme à vos attente
      à
    </p>

    <h2>Livraison</h2>
    <p>La livraison peut se faire sur n'omporte quel serveur si vous en possédez déjà un, sinon je peux m'occuper de tout mettre en place, dans tout les cas ces frais sont compris dans le tarif de base</p>

    <h2>Maintenance</h2>
    <p>La maintenance comprend : </p>
    <ul>
      <li>La facturation annuel du serveur.</li>
      <li>Le nom de domaine.</li>
      <li>Un backup de mon côté si il y avait un problème, rien ne serait perdu.</li>
      <li>L'intégralité de la gestion est réalisée de mon côté (s'il y a un soucis tu m'appelle et je remets le site en ligne au plus vite), vous n'aurez rien à faire.</li>
      <li>La gestion du certificat SSL (en gros le site est sécurisé ça rassure les utilisateurs, c'est le screen shot).</li>
      <li>La gratuité de ma main d'œuvre si vous souhaitez changer d'hébergeur, je m'occupe de tout avec le prochain prestataire ou vous-même.</li>
    </ul>

    <h2>Tarification</h2>
    <p>
      <strong>1000€</strong>
      <strong>100€</strong> TTC annuel
      Si vous souhaite plus de chsoe comme des onglet (250€ par onglet) plus de section (autour des 200€) ... je peux bien sur ajouter cela, nous en discuterons entre nous
    </p>

    -->
  </section>
  <advices-component/>
  <call-to-action-component/>
</template>

<script>
    import {useHead} from "@vueuse/head";
    import AdvicesComponent from "@/components/AdvicesComponent.vue";
    import CallToActionComponent from "@/components/CallToActionComponent.vue";

    export default {
        name: 'ShowcaseWebsiteView',
      components: {CallToActionComponent, AdvicesComponent},
        mounted() {
          useHead({
            title: 'Citron Digital - Création de Site Vitrine | Conception Web et SEO',
            meta: [
              { name: 'description', content: 'Découvrez le processus de création de site vitrine chez Citron Digital. Nous assurons une conception sur mesure, pensée pour le référencement (SEO) et adaptée à vos besoins.'},
              { name: 'keywords', content: 'création de site vitrine, processus de création web, site vitrine sur mesure, conception de site web, SEO, développement de site internet, Citron Digital, Toulouse'}
            ]
          });
        }
    }
</script>

<style lang="stylus" scoped>
    @import '~/src/common/style/constants.styl';

    .showcase-website-section
      padding-left 10%
      padding-right 10%
      margin-bottom 50px
      h1
        margin-top 2em
        margin-bottom 1em
        text-align center
        font-size 2.8em

    strong
      color THEME_COLOR_SECONDARY

    h2.contact
      padding-top 1em
      text-align center
      font-size 2em

    .button-container
      display flex
      justify-content center
      a.hyperlink
        margin-top 50px
        max-width 380px
        button
          box-shadow 0 0 5px #113f59
          background-color #c39e02
          border none
          cursor pointer
          margin-top 25px
          height 70px
          border-radius 3px
          display flex
          align-items center
          span
            font-size 1.2em
            padding-left 15px
            padding-right 15px
            font-weight bold
            color #162537
</style>
